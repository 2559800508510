define("cheddargorge/routes/order/show/payment/tesco", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service"], function (_exports, _route, _rsvp, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    session: (0, _service.inject)('session-user'),
    model() {
      const store = this.store;
      const order = this.modelFor('order.show');
      return store.createRecord('payment', {
        amount: 0,
        order: order,
        shopPaymentMethod: this.tescoPaymentMethod(order),
        token: '',
        capture: false
      });
    },
    async afterModel(payment) {
      const order = payment.order;
      (0, _object.set)(this, 'headData.title', 'Clubcard Payment | Cheddar Gorge');
      (0, _object.set)(this, 'headData.description', '');
      let orderLines = await order.orderLines;
      await _rsvp.default.all(orderLines.mapBy('promotion'));
      let payments = await order.payments;
      let shopPaymentMethods = await _rsvp.default.all(payments.filterBy('isNew', false).mapBy('shopPaymentMethod'));
      await _rsvp.default.all(shopPaymentMethods.mapBy('paymentMethod'));
    },
    resetController(controller) {
      this._super(...arguments);
      (0, _object.set)(controller, 'errors', []);
    },
    actions: {
      createPayment(payment) {
        const token = payment.token;
        (0, _object.set)(this, 'controller.isSaving', true);
        return payment.save().then(this._didSave.bind(this)).catch(this._handleCreatePaymentErrors.bind(this)).finally(this._finishCreatingPayment.bind(this));
      }
    },
    tescoPaymentMethod(order) {
      const orderPaymentMethods = order.orderPaymentMethods;
      return orderPaymentMethods.mapBy('shopPaymentMethod').findBy('paymentMethod.content.name', 'Tesco Clubcard');
    },
    _didSave(payment) {
      const balance = (0, _object.get)(payment, 'order.balance');
      const session = this.session;
      if (balance <= 0) {
        session.resetBasket();
        return this.transitionTo('order.show.complete', (0, _object.get)(payment, 'order.uuid'));
      }
      return this.transitionTo('order.show.payment.index', (0, _object.get)(payment, 'order.uuid'));
    },
    _handleCreatePaymentErrors(e) {
      (0, _object.set)(this, 'controller.errors', e.errors);
    },
    _finishCreatingPayment() {
      (0, _object.set)(this, 'controller.isSaving', false);
    },
    _mapBy(key, list) {
      return _rsvp.default.all(list.mapBy(key));
    }
  });
  _exports.default = _default;
});