define("cheddargorge/controllers/partners", ["exports", "@ember/controller", "@ember/utils", "@ember/object", "@ember/service", "@ember/object/computed", "ember-concurrency"], function (_exports, _controller, _utils, _object, _service, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    goods: (0, _service.inject)("goods"),
    sessionUser: (0, _service.inject)("session-user"),
    basketItems: [],
    errors: null,
    isSubmitting: (0, _computed.alias)("checkCodeTask.isRunning"),
    partnerCode: (0, _computed.alias)("model"),
    checkCodeTask: (0, _emberConcurrency.task)(function* (partnerCode) {
      const basket = (0, _object.get)(this, "sessionUser.basket");
      const basketItems = basket.basketItems;
      yield this.removePartnerCodes(basketItems);
      const store = this.store;
      (0, _object.set)(this, "errors", null);
      let skus = yield store.query("sku", {
        filter: {
          product_slug: "partner",
          query: [["code", "like", partnerCode]]
        }
      });
      try {
        let sku = skus.firstObject;
        //validate code
        if ((0, _utils.isEmpty)(sku)) {
          throw {
            errors: ["not_found"]
          };
        }
        //Add code to basket
        const goods = this.goods;
        let newBasketItems = [];
        goods.createBasketItem(newBasketItems, sku, 1);
        yield goods.addToBasket(newBasketItems, (0, _object.get)(this, "sessionUser.basket"));
        return this.transitionToRoute("products.partners");
      } catch (e) {
        return (0, _object.set)(this, "errors", e.errors);
      }
    }).drop(),
    removePartnerCodes(basketItems) {
      const targetBasketItems = basketItems.filter(basketItem => {
        let sku = basketItem.sku;
        let product = sku.product;
        let slug = product.slug;
        return slug == "partner";
      });
      const goods = this.goods;
      return goods.destroyBasketItems(basketItems, targetBasketItems);
    },
    actions: {
      onCheckCode(partnerCode) {
        this.checkCodeTask.perform(partnerCode);
      }
    }
  });
  _exports.default = _default;
});