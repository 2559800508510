define("cheddargorge/controllers/index", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    openingTimesService: (0, _service.inject)("opening-times"),
    isOpenToday: (0, _computed.alias)("openingTimesService.isOpenToday"),
    todaysOpeningTime: (0, _computed.alias)("openingTimesService.todaysOpeningTime"),
    todaysClosingTime: (0, _computed.alias)("openingTimesService.todaysClosingTime"),
    dummy: (0, _computed.alias)("model")
  });
  _exports.default = _default;
});