define("cheddargorge/components/ui-modal/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "ui-modal": "_ui-modal_1q9cem",
    "modal-dialog-wrapper": "_modal-dialog-wrapper_1q9cem",
    "modal-dialog": "_modal-dialog_1q9cem",
    "is-narrow": "_is-narrow_1q9cem",
    "is-medium": "_is-medium_1q9cem",
    "close-btn": "_close-btn_1q9cem"
  };
  _exports.default = _default;
});