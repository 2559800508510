define("cheddargorge/routes/error404", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _route, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RedirectList = {
    '/armed-forces': {
      route: 'products.armed-forces'
    },
    '/armedforces': {
      route: 'products.armed-forces'
    },
    '/tesco': {
      route: 'products.tesco'
    },
    '/tescotickets': {
      route: 'products.tesco'
    },
    '/tesco-tickets': {
      route: 'products.tesco'
    },
    '/caravanclub': {
      route: 'products.caravan-club'
    },
    '/caravan-club': {
      route: 'products.caravan-club'
    },
    '/emergencyservices': {
      route: 'products.emergency-services'
    },
    '/emergency-services': {
      route: 'products.emergency-services'
    },
    '/x-treme': {
      route: 'rocksport.index'
    },
    '/x-treme/adventure-caving': {
      route: 'rocksport.attraction',
      slug: 'adventure-caving'
    },
    '/x-treme/cave-abseiling': {
      route: 'rocksport.attraction',
      slug: 'cave-abseiling'
    },
    '/x-treme/cheddar-gorge-climbs': {
      route: 'rocksport.attraction',
      slug: 'cheddar-gorge-climbs'
    },
    '/x-treme/rock-climbing': {
      route: 'rocksport.attraction',
      slug: 'rock-climbing'
    },
    '/plan-your-visit/x-treme-price-info': {
      route: 'products.index'
    },
    '/plan-your-visit/eating-drinking': {
      route: 'things-to-do.eating-and-drinking'
    },
    '/plan-your-visit/shopping': {
      route: 'things-to-do.shopping-and-souvenirs'
    },
    '/events': {
      route: 'whats-on.index'
    },
    '/education/plan': {
      route: 'education.resources.planning-and-booking'
    },
    '/education/prices-and-payment': {
      route: 'education.resources.show',
      slug: 'prices-and-payment'
    },
    '/education/health-and-safety': {
      route: 'education.resources.show',
      slug: 'health-and-safety'
    },
    '/education/geography-and-rocks': {
      route: 'education.resources.show',
      slug: 'rocks-and-geography-for-teachers'
    },
    '/education/prehistory': {
      route: 'education.resources.show',
      slug: 'prehistory-for-teachers-cpd'
    },
    '/education/wildlife-and-ecology': {
      route: 'education.resources.show',
      slug: 'wildlife-and-ecology-for-teachers'
    },
    '/education/business-and-tourism': {
      route: 'education.resources.show',
      slug: 'business-and-tourism-for-teachers'
    },
    '/plan-your-visit/opening-dates-and-times': {
      route: 'visitor-info.opening-times'
    },
    '/plan-your-visit/cheddar-gorge-map': {
      route: 'visitor-info.maps'
    },
    '/plan-your-visit/how-to-find-us': {
      route: 'visitor-info',
      slug: 'find-us'
    },
    '/plan-your-visit/safety-and-accessibility': {
      route: 'visitor-info',
      slug: 'safety-and-accessibility'
    },
    '/about/contact-us': {
      route: 'visitor-info',
      slug: 'contact-us'
    },
    '/plan-your-visit/accommodation': {
      route: 'accommodation'
    },
    '/plan-your-visit/parking-facilities': {
      route: 'index'
    },
    '/corporate': {
      route: 'business.index'
    },
    '/corporate/abseiling': {
      route: 'business.index'
    },
    '/corporate/caving': {
      route: 'business.index'
    },
    '/corporate/rock-climbing': {
      route: 'business.index'
    },
    '/buy-tickets': {
      route: 'products.index'
    },
    '/copyright-statement': {
      route: 'mumbo-jumbo.show',
      slug: 'copyright'
    },
    '/disclaimer': {
      route: 'mumbo-jumbo.show',
      slug: 'disclaimer'
    },
    '/buy-tickets/change-ticket-date': {
      route: 'products.index'
    },
    '/buy-tickets/e-ticket-terms-and-conditions': {
      route: 'products.index'
    },
    '/privacy-policy': {
      route: 'mumbo-jumbo.show',
      slug: 'privacy-policy'
    },
    '/carticket': {
      route: 'products.index'
    },
    '/plan-your-visit/promote-us': {
      route: 'index'
    },
    '/explore/the-crystal-quest': {
      route: 'explore.index'
    },
    '/gromit-unleashed': {
      route: 'index'
    },
    '/interest': {
      route: 'index'
    },
    '/interest/adventure-experience': {
      route: 'index'
    },
    '/interest/great-family-day-out': {
      route: 'index'
    },
    '/interest/group-visit': {
      route: 'groups.index'
    },
    '/interest/history-heritage': {
      route: 'index'
    },
    '/interest/nature-conservation': {
      route: 'index'
    },
    '/interest/school-or-college-visit': {
      route: 'education.index'
    },
    '/future-plans': {
      route: 'index'
    },
    '/photo-gallery': {
      route: 'index'
    },
    '/plan-your-visit/cheddar-gorge-events': {
      route: 'index'
    },
    '/plan-your-visit/visitor-reviews': {
      route: 'index'
    },
    '/plan-your-visit/tickets-price': {
      route: 'index'
    },
    '/sitemap': {
      route: 'index'
    },
    '/tickets/gorge-and-caves-explorer-concession-ticket': {
      route: 'product',
      slug: 'gorge-and-caves-explorer-ticket'
    },
    '/tickets/tesco-concessionary-gorge-and-caves-explorer-ticket': {
      route: 'product',
      slug: 'tesco-gorge-and-caves-explorer-ticket'
    },
    '/tickets/accommodation-gorge-and-caves-explorer-ticket': {
      route: 'product',
      slug: 'accommodation-gorge-and-caves-explorer-ticket'
    },
    '/tickets/rocksport-freefall': {
      route: 'product',
      slug: 'rocksport-freefall'
    },
    '/tickets/rocksport-brave-voucher': {
      route: 'product',
      slug: 'rocksport-brave-voucher'
    },
    '/tickets/staff-day-ticket': {
      route: 'product',
      slug: 'staff-day-ticket'
    },
    '/tickets/rocksport-rock-climbing': {
      route: 'product',
      slug: 'rocksport-rock-climbing'
    },
    '/tickets/corporate-rewards-gorge-&-caves-explorer-ticket': {
      route: 'product',
      slug: 'corporate-rewards-gorge-&-caves-explorer-ticket'
    },
    '/tickets/christmas-at-cheddar': {
      route: 'product',
      slug: 'christmas-at-cheddar'
    },
    '/tickets/gorge-and-caves-explorer-ticket': {
      route: 'product',
      slug: 'gorge-and-caves-explorer-ticket'
    },
    '/tickets/gorge-and-caves-group-explorer-ticket': {
      route: 'product',
      slug: 'gorge-and-caves-group-explorer-ticket'
    },
    '/tickets/rocksport-junior-rock-climbing': {
      route: 'product',
      slug: 'rocksport-junior-rock-climbing'
    },
    '/tickets/rocksport-caving': {
      route: 'product',
      slug: 'rocksport-caving'
    },
    '/tickets/rocksport-junior-caving': {
      route: 'product',
      slug: 'rocksport-junior-caving'
    },
    '/tickets/emergency-services-gorge-and-caves-explorer-ticket': {
      route: 'product',
      slug: 'emergency-services-gorge-and-caves-explorer-ticket'
    },
    '/tickets/armed-forces-gorge-and-caves-explorer-ticket': {
      route: 'product',
      slug: 'armed-forces-gorge-and-caves-explorer-ticket'
    },
    '/tickets/tesco-gorge-and-caves-explorer-ticket': {
      route: 'product',
      slug: 'tesco-gorge-and-caves-explorer-ticket'
    }
  };
  var _default = _route.default.extend({
    headData: (0, _service.inject)(),
    router: (0, _service.inject)(),
    // currentURL: computed("router.currentURL", function() {
    //   return this.router.currentURL;
    // }),

    beforeModel(transition) {
      let url = window.location.pathname;
      if (url.charAt(url.length - 1) == '/') {
        url = url.slice(0, url.length - 1);
      }
      const redirect = RedirectList[url];
      if ((0, _utils.isPresent)(redirect)) {
        if ((0, _utils.isPresent)(redirect.slug)) {
          this.transitionTo(redirect.route, redirect.slug);
        } else if ((0, _utils.isPresent)(redirect.href)) {
          window.location.href = redirect.href;
          transition.abort();
        } else {
          this.transitionTo(redirect.route);
        }
      }
    },
    afterModel() {
      (0, _object.set)(this, 'headData.title', '404 | Page not found');
      (0, _object.set)(this, 'headData.description', '');
    }
  });
  _exports.default = _default;
});