define("cheddargorge/components/template/product-list", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isLoading}}
  {{else}}
    {{#ui-background "very-light-grain"}}
  
      <GlobalNavBuffer />
  
      <SectionIntro>
        <h1>{{this.productList.heading}}</h1>
        {{wysiwyg-field this.productList.introduction}}
  
      </SectionIntro>
  
      {{#if this.isLoadingProducts}}
      {{else}}
        <ProductCards as |card|>
          {{#each this.products as |product|}}
            <card.item 
              @cardImage={{product.attrs.cardHeroImage.originalUrl}} 
              @product={{product}} 
              @ctaLinkLabel="Prices and booking" 
              @label={{product.attributes.tagline}}
            >
              <div class="template--title">
                {{product.name}}
              </div>
              <div class="template--caption">
                {{product.summary}}
              </div>
            </card.item>
          {{/each}}
  
        </ProductCards>
      {{/if}}
  
      {{#if (not (is-wysiwyg-empty this.productList.needToKnow))}}
        <SectionBody>
          <h4>Need to know</h4>
  
          {{wysiwyg-field this.productList.needToKnow}}
  
        </SectionBody>
      {{/if}}
  
      <PurchasePromise />
  
    {{/ui-background}}
  
  {{/if}}
  
  <GlobalFooter />
  */
  {
    "id": "/6vquqpV",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[],[]],[[[6,[39,1],[\"very-light-grain\"],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n\\n    \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,\"h1\"],[12],[1,[30,0,[\"productList\",\"heading\"]]],[13],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"productList\",\"introduction\"]]],null]],[1,\"\\n\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isLoadingProducts\"]],[[],[]],[[[1,\"      \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"products\"]]],null]],null],null,[[[1,\"          \"],[8,[30,1,[\"item\"]],null,[[\"@cardImage\",\"@product\",\"@ctaLinkLabel\",\"@label\"],[[30,2,[\"attrs\",\"cardHeroImage\",\"originalUrl\"]],[30,2],\"Prices and booking\",[30,2,[\"attributes\",\"tagline\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"template--title\"],[12],[1,\"\\n              \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"template--caption\"],[12],[1,\"\\n              \"],[1,[30,2,[\"summary\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"\\n      \"]],[1]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[28,[37,8],[[28,[37,9],[[30,0,[\"productList\",\"needToKnow\"]]],null]],null],[[[1,\"      \"],[8,[39,10],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,\"h4\"],[12],[1,\"Need to know\"],[13],[1,\"\\n\\n        \"],[1,[28,[35,4],[[30,0,[\"productList\",\"needToKnow\"]]],null]],[1,\"\\n\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,11],null,null,null],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[8,[39,12],null,null,null]],[\"card\",\"product\"],false,[\"if\",\"ui-background\",\"global-nav-buffer\",\"section-intro\",\"wysiwyg-field\",\"product-cards\",\"each\",\"-track-array\",\"not\",\"is-wysiwyg-empty\",\"section-body\",\"purchase-promise\",\"global-footer\"]]",
    "moduleName": "cheddargorge/components/template/product-list.hbs",
    "isStrictMode": false
  });
  class TemplateProductList extends _component2.default {
    get isLoading() {
      return this.args.productListData.isLoading;
    }
    get isLoadingProducts() {
      return this.args.productsData.isLoading;
    }
    get productList() {
      return this.args.productListData.value;
    }
    get products() {
      return this.args.productsData.value.sortBy('attrs.order');
    }
  }
  _exports.default = TemplateProductList;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TemplateProductList);
});