define("cheddargorge/components/ui-modal/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yggixSiK",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"modal-dialog-wrapper\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"ui-modal--modal-dialog \",[28,[37,0],[\"modal-dialog\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]]]],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"      \"],[6,[39,3],null,[[\"class\",\"route\"],[[28,[37,4],[[28,[37,0],[\"close-btn\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]],null],[33,2]]],[[\"default\"],[[[[1,\"x\"]],[]]]]],[1,\"\\n\"]],[]],null],[41,[33,5],[[[1,\"      \"],[11,0],[16,0,[29,[[28,[37,0],[\"close-btn\"],[[\"from\"],[\"cheddargorge/components/ui-modal/styles\"]]]]]],[4,[38,6],[[30,0],[33,5]],null],[12],[1,\"x\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"local-class\",\"if\",\"closeLink\",\"link-to\",\"concat\",\"closeAction\",\"action\",\"yield\"]]",
    "moduleName": "cheddargorge/components/ui-modal/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});