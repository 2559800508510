define("cheddargorge/components/commerce/product/selector-card/component", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "@glimmer/tracking", "ember-concurrency-decorators"], function (_exports, _component, _object, _service, _utils, _tracking, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const DEFAULT_MAX_QUANTITY = 32;
  let CommerceProductSelectorCard = (_class = class CommerceProductSelectorCard extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "goods", _descriptor, this);
      _initializerDefineProperty(this, "sessionUser", _descriptor2, this);
      _initializerDefineProperty(this, "basketItems", _descriptor3, this);
      _initializerDefineProperty(this, "isShowingExtraInformation", _descriptor4, this);
      _initializerDefineProperty(this, "currentIndex", _descriptor5, this);
      _initializerDefineProperty(this, "galleryElements", _descriptor6, this);
    }
    get productImages() {
      let images = this.args.product.get('attributes').images;
      if ((0, _utils.isNone)(images)) {
        return [];
      }
      if (typeof images == 'object') {
        return images;
      }
      return [images];
    }
    get isVisible() {
      let basketSlugs = this.sessionUser.get('basket').get('basketItems').mapBy('sku.product.slug').uniq();
      if (this.hasProductAssociation) {
        return this.associatedProductSlugs.any(slug => basketSlugs.includes(slug));
      }
      return true;
    }
    get associatedProductSlugs() {
      let associatedProducts = this.args.product.get(
      //@ts-ignore
      'attributes.associated_products');
      if ((0, _utils.isPresent)(associatedProducts)) {
        return associatedProducts.split(',').map(slug => slug.trim());
      }
      return [];
    }
    get associatedProductsQuantity() {
      let basketItems = this.sessionUser.get('basket').get('basketItems').filter(basketItem => this.associatedProductSlugs.includes(basketItem.get('sku').get('product').get('slug')));
      return basketItems.reduce((total, basketItem) => total + basketItem.get('quantity'), 0);
    }
    get hasProductAssociation() {
      return (0, _utils.isPresent)(this.associatedProductSlugs);
    }
    get maxQuantity() {
      if (this.hasProductAssociation) {
        let limitByAssociated = this.args.product.get(
        //@ts-ignore
        'attributes.associated_products_max');
        if (limitByAssociated) {
          return this.associatedProductsQuantity;
        }
      }
      return DEFAULT_MAX_QUANTITY;
    }
    get isSubmitDisabled() {
      return this.hasQuantity == false || this.isAdding == true;
    }
    get hasQuantity() {
      let quantity = this.basketItems.reduce((total, basketItem) => total + basketItem.get('quantity'), 0);
      return quantity > 0;
    }
    get totalPrice() {
      return this.basketItems.reduce((total, basketItem) => total + basketItem.get('quantity') * basketItem.get('price'), 0);
    }
    get isAdding() {
      //@ts-ignore
      return this.addToBasketTask.isRunning;
    }
    onUpdateCurrentIndex(index, intersectionEntry) {
      if (intersectionEntry[0].isIntersecting) {
        this.currentIndex = index;
      }
    }
    onChangeCurrentIndex(index) {
      this.currentIndex = index;
      this.galleryElements[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
    onNextImage() {
      if (this.currentIndex < this.galleryElements.length - 1) {
        this.galleryElements[this.currentIndex + 1].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    }
    onPrevImage() {
      if (this.currentIndex > 0) {
        this.galleryElements[this.currentIndex - 1].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
      }
    }
    onInsertGallery(element) {
      this.galleryElements = [...element.querySelectorAll('.' + this.styleNamespace + ' li.image')];
    }
    onCreateBasketItem(basketItems, sku, quantity) {
      return this.goods.createBasketItem(basketItems, sku, quantity);
    }
    onDestroyBasketItem(basketItems, basketItem) {
      this.goods.destroyBasketItem(basketItems, basketItem);
    }
    onAddToBasket(basketItems) {
      //@ts-ignore
      this.addToBasketTask.perform(this.goods, this.sessionUser.get('basket'), basketItems);
    }
    *addToBasketTask(goods, basket, basketItems) {
      yield goods.addToBasket(basketItems, basket);
      basketItems.clear();
      window.scrollTo(0, 0);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "goods", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "basketItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isShowingExtraInformation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "galleryElements", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onUpdateCurrentIndex", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdateCurrentIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeCurrentIndex", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCurrentIndex"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNextImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onNextImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPrevImage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPrevImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsertGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsertGallery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCreateBasketItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCreateBasketItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDestroyBasketItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDestroyBasketItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAddToBasket", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onAddToBasket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToBasketTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "addToBasketTask"), _class.prototype)), _class);
  _exports.default = CommerceProductSelectorCard;
});