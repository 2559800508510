define("cheddargorge/components/basket-item-summary/component", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: 'basket-item-summary',
    localClassNames: 'basket-item-summary',
    localClassNameBindings: [':basket-item-summary', 'isEditing', 'hasZeroQuantity'],
    defaultMaxQuantity: 32,
    defaultMinQuantity: 0,
    defaultSkuMinQuantity: 1,
    isEditing: false,
    product: (0, _computed.alias)('basketItem.sku.product'),
    productAttributes: (0, _computed.alias)('product.attributes'),
    skuAttributes: (0, _computed.alias)('basketItem.sku.attributes'),
    skuMaxQuantity: (0, _computed.alias)('basketItem.sku.maxQuantity'),
    stockQuantity: (0, _computed.alias)('basketItem.sku.stockQuantity'),
    hasSkuMaxQuantity: (0, _computed.gt)('skuMaxQuantity', 0),
    hasZeroQuantity: (0, _computed.equal)('basketItem.quantity', 0),
    date: (0, _computed.alias)('skuAttributes.bookable-date.firstObject'),
    hasDate: (0, _computed.notEmpty)('date'),
    session: (0, _computed.alias)('skuAttributes.session-start-time.firstObject'),
    hasSession: (0, _computed.notEmpty)('session'),
    bundleUUID: (0, _computed.alias)('basketItem.metadata.bundleUUID'),
    hasBundleUUID: (0, _computed.notEmpty)('bundleUUID'),
    productQuantities: (0, _computed.mapBy)('productBasketItems', 'quantity'),
    productQuantity: (0, _computed.sum)('productQuantities'),
    productBasketItems: (0, _computed.filter)('basketItems', function (basketItem) {
      return (0, _object.get)(basketItem, 'sku.product.id') === (0, _object.get)(this, 'basketItem.sku.product.id');
    }),
    images: (0, _object.computed)('basketItem.sku.product.attributes.images.[]', function () {
      let images = (0, _object.get)(this, 'basketItem.sku.product.attributes.images') === undefined ? [] : (0, _object.get)(this, 'basketItem.sku.product.attributes.images');
      if (Array.isArray(images) === false) {
        images = [images];
      }
      return images;
    }),
    bundledBasketItems: (0, _object.computed)('basketItem.id', 'basketItems.@each.metadata', 'bundleUUID', 'hasBundleUUID', 'targetBasketItem', function () {
      const hasBundleUUID = this.hasBundleUUID;
      const bundleUUID = this.bundleUUID;
      const targetBasketItem = this.targetBasketItem;
      return this.basketItems.filter(basketItem => {
        if (basketItem == targetBasketItem) {
          return false;
        }
        if (hasBundleUUID) {
          return bundleUUID === (0, _object.get)(basketItem, 'metadata.bundleUUID');
        }
      });
    }),
    isHidden: (0, _computed.alias)('productAttributes.is-hidden'),
    // canEdit: not('productAttributes.is-not-editable'),
    canEdit: false,
    productMaxQuantity: (0, _object.computed)('productAttributes', 'defaultMaxQuantity', function () {
      let productAttributes = this.productAttributes;
      if (productAttributes['max-quantity']) {
        return productAttributes['max-quantity'];
      } else {
        return this.defaultMaxQuantity;
      }
    }),
    productMinQuantity: (0, _object.computed)('productAttributes', 'defaultMinQuantity', function () {
      let productAttributes = this.productAttributes;
      if (productAttributes['min-quantity']) {
        return productAttributes['min-quantity'];
      } else {
        return this.defaultMinQuantity;
      }
    }),
    skuMinQuantity: (0, _object.computed)('productAttributes', 'defaultSkuMinQuantity', function () {
      let productAttributes = this.productAttributes;
      if (productAttributes['sku-min-quantity']) {
        return productAttributes['sku-min-quantity'];
      } else {
        return this.defaultSkuMinQuantity;
      }
    }),
    minQuantity: (0, _object.computed)('productQuantity', 'productMinQuantity', 'skuMinQuantity', 'basketItem.quantity', function () {
      const productMinQuantity = this.productMinQuantity;
      const skuMinQuantity = this.skuMinQuantity;
      if (productMinQuantity === 0 && skuMinQuantity == 0) {
        return 0;
      }
      if (skuMinQuantity > 0) {
        return skuMinQuantity;
      }
      return Math.max(0, this.productMinQuantity - this.productQuantity + (0, _object.get)(this, 'basketItem.quantity'));
    }),
    maxQuantity: (0, _object.computed)('hasSkuMaxQuantity', 'skuMaxQuantity', 'stockQuantity', 'defaultMaxQuantity', 'productQuantity', 'productMaxQuantity', 'basketItem.quantity', function () {
      if (this.hasSkuMaxQuantity) {
        return this.skuMaxQuantity;
      }
      let maxQuantity = Math.min(this.stockQuantity, this.productMaxQuantity);
      return maxQuantity - this.productQuantity + (0, _object.get)(this, 'basketItem.quantity');
    }),
    linePrice: (0, _object.computed)('basketItem.{quantity,sku.price}', 'bundledBasketItems.@each.price', function () {
      let totalPrice = this.bundledBasketItems.reduce((totalPrice, basketItem) => totalPrice + (0, _object.get)(basketItem, 'sku.price'), 0) + (0, _object.get)(this, 'basketItem.sku.price');
      return (0, _object.get)(this, 'basketItem.quantity') * totalPrice;
    }),
    editSubmitLabel: (0, _object.computed)('basketItem.quantity', function () {
      if ((0, _object.get)(this, 'basketItem.quantity') === 0) {
        return 'Remove';
      }
      return 'OK';
    }),
    actions: {
      toggleEditing() {
        this.toggleProperty('isEditing');
      },
      saveBasketItem(basketItem) {
        this.saveBasketItem(basketItem).then(() => (0, _object.set)(this, 'isEditing', false));
      },
      cancel(basketItem) {
        if (basketItem.hasDirtyAttributes) {
          basketItem.rollbackAttributes();
          this.bundledBasketItems.invoke('rollbackAttributes');
        }
        (0, _object.set)(this, 'isEditing', false);
      },
      setQuantity(quantity) {
        quantity = Math.max(0, parseInt(quantity, 10));
        (0, _object.set)(this, 'basketItem.quantity', quantity);
        this.bundledBasketItems.invoke('set', 'quantity', quantity);
      }
    }
  });
  _exports.default = _default;
});