define("cheddargorge/routes/index", ["exports", "@ember/service", "@ember/routing/route", "rsvp", "@ember/object"], function (_exports, _service, _route, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    goods: (0, _service.inject)("goods"),
    session: (0, _service.inject)("session-user"),
    model() {
      return _rsvp.default.hash({
        carousel: this.store.findAll("banner-carousel").then(this._sortBanners.bind(this)),
        instagramImages: this.store.findAll("instagram-image")
      });
    },
    actions: {
      addToBasket(basketItems) {
        const goods = this.goods;
        (0, _object.set)(this, "controller.isSubmitting", true);
        goods.addToBasket(basketItems, (0, _object.get)(this, "session.basket")).then(this._addedToBasket.bind(this)).catch(this._setErrors.bind(this)).finally(this._finishAddingToBasket.bind(this));
      }
    },
    _getFirstObject(list) {
      return list.firstObject;
    },
    _sortBanners(banners) {
      return banners.sortBy("order");
    },
    _addedToBasket() {
      return this.transitionTo("basket");
    },
    _setErrors(e) {
      (0, _object.set)(this, "controller.errors", e.errors);
    },
    _finishAddingToBasket() {
      (0, _object.set)(this, "controller.isSubmitting", false);
    }
  });
  _exports.default = _default;
});