define("cheddargorge/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JBYL+pXU",
    "block": "[[[1,[34,0]],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\"],[1,[34,2]],[1,\"\\n\"],[1,[34,3]],[1,\"\\n\"],[8,[39,4],null,null,null],[1,\"\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"head-layout\",\"global/app-new-version\",\"privacy-notification\",\"notification-card\",\"global/nav\",\"component\",\"-outlet\"]]",
    "moduleName": "cheddargorge/templates/application.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});