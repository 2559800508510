define("cheddargorge/components/product-cross-sell/component", ["exports", "@ember/component", "@ember/service", "ember-concurrency-decorators", "@ember/object/computed", "@ember/object", "@ember/utils", "ember-css-modules"], function (_exports, _component, _service, _emberConcurrencyDecorators, _computed, _object, _utils, _emberCssModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ProductCrossSell = (_dec = (0, _emberCssModules.localClassNames)('product-cross-sell'), _dec2 = (0, _computed.or)('loadProductTask.isRunning', 'loadBasketTask.isRunning', 'setupTask.isRunning'), _dec3 = (0, _computed.filter)('basketItems', function (basketItem) {
    return (0, _object.get)(basketItem, 'sku.product.slug') === this.productSlug;
  }), _dec4 = (0, _computed.notEmpty)('productBasketItems'), _dec5 = (0, _computed.empty)('skus'), _dec(_class = (_class2 = class ProductCrossSell extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "productSlug", void 0);
      _defineProperty(this, "date", void 0);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "sessionUser", _descriptor3, this);
      _defineProperty(this, "product", void 0);
      _defineProperty(this, "basketItems", []);
      _defineProperty(this, "skus", []);
      _initializerDefineProperty(this, "isLoading", _descriptor4, this);
      _initializerDefineProperty(this, "productBasketItems", _descriptor5, this);
      _initializerDefineProperty(this, "hasProductInBasket", _descriptor6, this);
      _initializerDefineProperty(this, "hasNoSkus", _descriptor7, this);
    }
    *setupTask(productSlug) {
      //@ts-ignore
      yield this.loadProductTask.perform(productSlug);
      //@ts-ignore
      yield this.loadDateSkusTask.perform(this.date, this.product);
      //@ts-ignore
      yield this.loadBasketTask.perform();
      if (this.hasProductInBasket || this.hasNoSkus) {
        return this.router.transitionTo('basket');
      }
    }
    *loadBasketTask() {
      let basket = this.sessionUser.basket;
      let basketItems = yield this.store.query('basket-item', {
        filter: {
          basket_id: (0, _object.get)(basket, 'id')
        },
        include: 'sku,sku.product'
      });
      (0, _object.set)(this, 'basketItems', basketItems);
    }
    *loadProductTask(productSlug) {
      let products = yield this.store.query('product', {
        filter: {
          slug: productSlug
        }
      });
      (0, _object.set)(this, 'product', (0, _object.get)(products, 'firstObject'));
    }
    *loadDateSkusTask(date, product) {
      let skus = yield this.store.query('sku', {
        filter: {
          product_id: (0, _object.get)(product, 'id'),
          query: [['bookable-date', 'is_same', date.format('YYYY-MM-DDTHH:mm:ss\\Z')]]
        }
      });
      (0, _object.set)(this, 'skus', skus);
    }
    didReceiveAttrs() {
      if ((0, _utils.isNone)(this.productSlug)) {
        return this.router.transitionTo('basket');
      }

      //@ts-ignore
      this.setupTask.perform(this.productSlug);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "sessionUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "productBasketItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "hasProductInBasket", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "hasNoSkus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "setupTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "setupTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadBasketTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadBasketTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadProductTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadProductTask"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "loadDateSkusTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class2.prototype, "loadDateSkusTask"), _class2.prototype)), _class2)) || _class);
  _exports.default = ProductCrossSell;
});