define("cheddargorge/components/product-details/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "@ember/service", "cheddargorge/helpers/sku-title"], function (_exports, _computed, _component, _object, _utils, _service, _skuTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    goods: (0, _service.inject)('goods'),
    localClassNames: 'product-details',
    classNames: 'product-details',
    description: {
      isDescription: true
    },
    skus: [],
    hasFullPrice: (0, _object.computed)('product', 'skus.@each.isLoaded', function () {
      return this.product.get('skuSchema').findBy('slug', 'full-price');
    }),
    hasPriceBands: (0, _object.computed)('product.skuFields.@each.slug', function () {
      return (0, _utils.isPresent)(this.product.get('skuSchema').findBy('slug', 'price-band'));
    }),
    hasProductTitleTemplate: (0, _computed.notEmpty)('productTitleTemplate'),
    priceTableFields: (0, _object.computed)('hasFullPrice', 'hasPriceBands', function () {
      if (this.hasPriceBands) {
        return [{
          heading: 'Visitor',
          path: 'title',
          isStrong: true
        }, {
          heading: 'Price',
          path: 'Off-peak',
          isCentered: true,
          isStrong: true
        }, {
          heading: 'Peak Price',
          path: 'Peak',
          hasStrikeThrough: false,
          isCentered: true,
          isStrong: true
        }];
      }
      if (this.hasFullPrice) {
        return [{
          heading: 'Visitor',
          path: 'title',
          isStrong: true
        }, {
          heading: 'Gate Price',
          path: 'fullPrice',
          hasStrikeThrough: true,
          isCentered: true,
          isStrong: false
        }, {
          heading: 'Online Price*',
          path: 'price',
          isCentered: true,
          isStrong: true
        }];
      }
      return [{
        heading: 'Visitor',
        path: 'title',
        isStrong: true
      }, {
        heading: ''
      }, {
        heading: 'Price*',
        path: 'price',
        isStrong: true,
        isCentered: true
      }];
    }),
    priceTableData: (0, _object.computed)('hasFullPrice', 'hasPriceBands', 'priceListRowLabel', 'productTitleTemplate', 'skus.[]', 'skus.firstObject.skuFields', function () {
      let skuFields = (0, _object.get)(this, 'skus.firstObject.skuFields');
      // Return blank if no sku is specified
      if ((0, _utils.isNone)(skuFields)) {
        return;
      }
      let goods = this.goods;
      let rows = this.skus.reduce((rows, sku) => {
        let title = (0, _skuTitle.getSkuTitle)(this.goods, sku, this.productTitleTemplate);
        if (this.hasPriceBands) {
          let priceBand = sku.get('attrs').priceBand;
          let row = rows.findBy('title', title);
          if ((0, _utils.isNone)(row)) {
            let row = {
              title,
              explanation: (0, _object.get)(sku, 'attributes.explanation.firstObject')
            };
            row[priceBand] = '£' + (sku.get('price') / 100).toFixed(2);
            rows.push(row);
          } else {
            row[priceBand] = '£' + (sku.get('price') / 100).toFixed(2);
          }
        } else {
          rows.push({
            title: title,
            price: '£' + (sku.get('price') / 100).toFixed(2),
            explanation: (0, _object.get)(sku, 'attributes.explanation.firstObject')
          });
        }
        return rows;
      }, []);
      return rows;
    })
  });
  _exports.default = _default;
});