define("cheddargorge/components/grid-cards-no-link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-cards-no-link": "_grid-cards-no-link_19sbcf",
    "grid-card": "_grid-card_19sbcf",
    "content": "_content_19sbcf"
  };
  _exports.default = _default;
});