define("cheddargorge/components/basket/warning-message", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::Message 
    local-class="message"
    @theme="error" 
  >
    <h3 local-class="title">{{@title}}</h3>
    {{yield}}
  </Ui::Message>
  */
  {
    "id": "pg7wGx6B",
    "block": "[[[8,[39,0],[[16,0,[29,[[28,[37,1],[\"message\"],[[\"from\"],[\"cheddargorge/components/basket/warning-message.scss\"]]]]]]],[[\"@theme\"],[\"error\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h3\"],[15,0,[29,[[28,[37,1],[\"title\"],[[\"from\"],[\"cheddargorge/components/basket/warning-message.scss\"]]]]]],[12],[1,[30,1]],[13],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"@title\",\"&default\"],false,[\"ui/message\",\"local-class\",\"yield\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessage extends _component2.default {}
  _exports.default = BasketWarningMessage;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessage);
});