define("cheddargorge/components/basket/warning-message.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "message": "_message_1hp7wf",
    "theme-error": "_theme-error_1hp7wf",
    "title": "_title_1hp7wf"
  };
  _exports.default = _default;
});