define("cheddargorge/components/opening-times-calendar/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed", "moment"], function (_exports, _component, _object, _service, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    localClassNames: 'opening-times-calendar',
    openingTimes: (0, _service.inject)('opening-times'),
    maxDate: (0, _computed.alias)('openingTimes.seasonEndDate'),
    key: (0, _computed.alias)('openingTimes.dateKeys'),
    days: (0, _object.computed)('displayDate', 'openingTimes', function () {
      let startDay = this.displayDate.clone().startOf('month').subtract(1, 'day').weekday(2);
      let finishDay = this.displayDate.clone().endOf('month').subtract(1, 'day').weekday(7);
      return this.openingTimes.range(startDay, finishDay);
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'minDate', (0, _moment.default)().startOf('month'));
    },
    actions: {
      changeDisplayDate(displayDate) {
        (0, _object.set)(this, 'displayDate', displayDate);
      }
    }
  });
  _exports.default = _default;
});