define("cheddargorge/components/custom-attributes-form/component", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/object/computed", "ember-css-modules"], function (_exports, _component, _object, _utils, _computed, _emberCssModules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let CustomAttributesForm = (_dec = (0, _emberCssModules.localClassNames)('custom-attributes-form'), _dec2 = (0, _computed.filter)('basketItems', function (basketItem) {
    let giftInfo = (0, _object.get)(basketItem, 'sku.attributes.gift-info');
    return (0, _utils.isPresent)(giftInfo) && giftInfo[0] == true;
  }), _dec(_class = (_class2 = class CustomAttributesForm extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "basketItems", void 0);
      _defineProperty(this, "onSubmit", void 0);
      _defineProperty(this, "heading", '');
      _defineProperty(this, "instructions", '');
      _defineProperty(this, "ages", ['0 to 12 months', '1 year old', '2 years old', '3 years old', '4 years old', '5 years old', '6 years old', '7 years old', '8 years old', '9 years old', '10 years old and over']);
      _initializerDefineProperty(this, "customAttributeBasketItems", _descriptor, this);
    }
    didReceiveAttrs() {
      //@ts-ignore
      this.customAttributeBasketItems.forEach(basketItem => {
        if ((0, _utils.isEmpty)((0, _object.get)(basketItem, 'metadata'))) {
          let metadata = [];
          for (let i = 0; i < (0, _object.get)(basketItem, 'quantity'); i++) {
            metadata.pushObject({
              fullName: '',
              age: '',
              favouriteHobbies: ''
            });
          }
          (0, _object.set)(basketItem, 'metadata', {
            gifts: metadata
          });
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "customAttributeBasketItems", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = CustomAttributesForm;
});