define("cheddargorge/components/basket/warning-message/only-christmas-tickets", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isVisible}}
    <Basket::WarningMessage 
      @title="You only have Christmas at Cheddar Tickets in your basket "
    >
      <p>
        Please remember that anyone meeting Father Christmas must have a valid Day Ticket. We would recommend purchasing your Day Tickets online now.
      </p>
    </Basket::WarningMessage>
  {{/if}}
  */
  {
    "id": "Fg7QJsvD",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[\"You only have Christmas at Cheddar Tickets in your basket \"]],[[\"default\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Please remember that anyone meeting Father Christmas must have a valid Day Ticket. We would recommend purchasing your Day Tickets online now.\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"basket/warning-message\"]]",
    "moduleName": "cheddargorge/components/basket/warning-message/only-christmas-tickets.hbs",
    "isStrictMode": false
  });
  class BasketWarningMessageOnlyChristmasTickets extends _component2.default {
    /**
     * Is the warning message visible? Shows if there are only Christmas tickets
     * and no entry tickets
     */
    get isVisible() {
      let christmasTickets = this.args.basketItems.filterBy('sku.content.product.slug', 'christmas-at-cheddar');
      let entryTickets = this.args.basketItems.filterBy('sku.product.attributes.is-entry-ticket', true);
      return (0, _utils.isPresent)(christmasTickets) && (0, _utils.isEmpty)(entryTickets);
    }
  }
  _exports.default = BasketWarningMessageOnlyChristmasTickets;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BasketWarningMessageOnlyChristmasTickets);
});