define("cheddargorge/components/select-tickets-form/component", ["exports", "@ember/component", "@ember/utils", "@ember/object", "moment", "@ember/service", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _utils, _object, _moment, _service, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  /* eslint-disable ember/no-classic-components */
  /* eslint-disable ember/require-tagless-components */
  /* eslint-disable ember/no-component-lifecycle-hooks */
  var _default = _component.default.extend({
    goods: (0, _service.inject)('goods'),
    router: (0, _service.inject)('router'),
    tickets: (0, _service.inject)('tickets'),
    store: (0, _service.inject)('store'),
    session: (0, _service.inject)('session-user'),
    localClassNames: 'select-tickets-form',
    isShowingCalendar: false,
    isShowingVisitors: false,
    hasCondition: false,
    hasAcceptedCondition: false,
    showCustomAttributesForm: false,
    showCrossSell: false,
    conditionMessage: 'I accept',
    emptySelectVisitorsLabel: 'Number of visitors',
    ticketHolderLabel: 'Visitor',
    whosVisitingLabel: "Who's visiting...",
    defaultProductMaxQuantity: 32,
    defaultProductMinQuantity: 0,
    isSubmitting: (0, _computed.alias)('addToBasketTask.isRunning'),
    date: _moment.default.utc().startOf('day').add(1, 'day'),
    minDate: (0, _object.computed)('product.attributes.release-date.firstObject', function () {
      if ((0, _utils.isPresent)((0, _object.get)(this, 'product.attributes.release-date.firstObject'))) {
        return _moment.default.utc((0, _object.get)(this, 'product.attributes.release-date.firstObject')).startOf('month');
      } else {
        return _moment.default.utc().startOf('month');
      }
    }),
    maxDate: _moment.default.utc('2024-02-28'),
    displayDate: _moment.default.utc().startOf('month'),
    isLoadingDates: (0, _computed.alias)('loadSkusTask.isRunning'),
    productFields: (0, _computed.alias)('product.productFields'),
    quantities: (0, _computed.mapBy)('visibleBasketItems', 'quantity'),
    persistedProductQuantities: (0, _computed.mapBy)('persistedProductBasketItems', 'quantity'),
    persistedProductQuantity: (0, _computed.sum)('persistedProductQuantities'),
    firstSku: (0, _computed.alias)('product.skus.firstObject'),
    hasBookableDateField: (0, _computed.filterBy)('productFields', 'slug', 'has-bookable-date'),
    isHidingForm: (0, _computed.and)('hasNoProductQuantityAvailable', 'isNotSubmitting'),
    isShowingForm: (0, _computed.not)('isHidingForm'),
    chooseSessions: (0, _computed.and)('product.hasSessions', 'isNotGift'),
    isBasketItemsEmpty: (0, _computed.empty)('basketItems'),
    totalVisitors: (0, _computed.sum)('quantities'),
    hasBasketItems: (0, _computed.gt)('totalVisitors', 0),
    pricedQuantities: (0, _computed.mapBy)('pricedBasketItems', 'quantity'),
    totalPricedQuantity: (0, _computed.sum)('pricedQuantities'),
    quantityIsValid: (0, _computed.and)('minQuantityIsValid', 'maxQuantityIsValid'),
    canSubmit: (0, _computed.and)('hasBasketItems', 'quantityIsValid', 'conditionIsPassed'),
    cannotSubmit: (0, _computed.not)('canSubmit'),
    hasProductQuantityAvailable: (0, _computed.gt)('availableProductQuantity', 0),
    hasNoProductQuantityAvailable: (0, _computed.not)('hasProductQuantityAvailable', 0),
    isNotSubmitting: (0, _computed.not)('isSubmitting'),
    hasMinQuantity: (0, _computed.gt)('productMinQuantity', 0),
    hasMaxQuantity: (0, _computed.gt)('productMaxQuantity', 0),
    hasSmallPrint: (0, _computed.or)('hasMinQuantity', 'hasMaxQuantity'),
    skuFieldArrays: (0, _computed.mapBy)('skusWithStock', 'skuFields'),
    skuDateFields: (0, _computed.filterBy)('skuFields', 'slug', 'bookable-date'),
    skuDateValueArrays: (0, _computed.mapBy)('skuDateFields', 'values'),
    dates: (0, _computed.uniq)('skuValueDates'),
    visibleBasketItems: (0, _computed.filterBy)('basketItems', 'isHidden', false),
    skusWithStock: (0, _computed.filter)('skus', function (sku) {
      if (sku.stockQuantity > 0) {
        return sku;
      }
    }),
    basketItemSkus: (0, _computed.mapBy)('basketItems', 'sku'),
    basketItemSkusAttributes: (0, _computed.mapBy)('basketItemSkus', 'attributes'),
    skuMinAvailable: (0, _object.computed)('product.attrs.skuMinAvailable', function () {
      return this.product.get('attrs.skuMinAvailable') ?? 0;
    }),
    requiresDate: (0, _object.computed)('hasBookableDateField.@each.values', function () {
      let hasBookableDateField = this.hasBookableDateField;
      if ((0, _utils.isEmpty)(hasBookableDateField)) {
        return true;
      }
      return (0, _object.get)(hasBookableDateField, 'firstObject.values') === undefined ? false : (0, _object.get)(hasBookableDateField, 'firstObject.values');
    }),
    showCapacityKey: (0, _object.computed)('product.attributes.show_capacity_key', function () {
      return this.product.get('attributes')['show_capacity_key'];
    }),
    showPriceBandKey: (0, _object.computed)('product.attributes', function () {
      return (0, _utils.isPresent)(this.product.get('skuSchema').findBy('slug', 'price-band'));
    }),
    groupUpsellThreshold: (0, _object.computed)('product.attributes.{group_upsell_threshold,groupUpsellThreshold}', function () {
      if (!(0, _utils.isNone)(this.product.get('attributes.group_upsell_threshold'))) {
        return this.product.get('attributes.group_upsell_threshold');
      }
      return this.product.get('attributes.groupUpsellThreshold');
    }),
    groupUpsellMessage: (0, _object.computed)('product.attributes.{group_upsell_message,groupUpsellMessage}', function () {
      if (!(0, _utils.isNone)(this.product.get('attributes.group_upsell_message'))) {
        return this.product.get('attributes.group_upsell_message');
      }
      return this.product.get('attributes.groupUpsellMessage');
    }),
    hasGiftInfo: (0, _object.computed)('basketItemSkusAttributes.@each.gift-info', function () {
      let giftInfo = this.basketItemSkusAttributes.find(attribute => {
        return (0, _object.get)(attribute, 'gift-info.firstObject') == true;
      });
      return (0, _utils.isPresent)(giftInfo);
    }),
    crossSellProductSlug: (0, _computed.alias)('product.attributes.cross-sell-product-slug'),
    hasCrossSell: (0, _computed.notEmpty)('crossSellProductSlug'),
    persistedProductBasketItems: (0, _computed.filter)('persistedBasketItems', function (persistedBasketItem) {
      return (0, _object.get)(persistedBasketItem, 'sku.product.id') === (0, _object.get)(this, 'product.id');
    }),
    productMaxQuantity: (0, _object.computed)('productFieldsHash', 'defaultProductMaxQuantity', function () {
      let productFields = this.productFieldsHash;
      if ((0, _utils.isPresent)(productFields['max-quantity'])) {
        return productFields['max-quantity'];
      } else {
        return this.defaultProductMaxQuantity;
      }
    }),
    productMinQuantity: (0, _object.computed)('productFieldsHash', 'defaultProductMinQuantity', function () {
      let productFields = this.productFieldsHash;
      if (productFields['min-quantity']) {
        return productFields['min-quantity'];
      } else {
        return this.defaultProductMinQuantity;
      }
    }),
    conditionIsPassed: (0, _object.computed)('hasCondition', 'hasAcceptedCondition', function () {
      const hasCondition = this.hasCondition;
      const hasAcceptedCondition = this.hasAcceptedCondition;
      if (hasCondition) {
        return hasAcceptedCondition;
      }
      return true;
    }),
    productFieldsHash: (0, _object.computed)('productFields.[]', function () {
      const goods = this.goods;
      const productFields = this.productFields;
      return goods.fieldsToHash(productFields);
    }),
    pricedBasketItems: (0, _computed.filter)('basketItems', function (basketItem) {
      return basketItem.price > 0;
    }),
    minQuantityIsValid: (0, _object.computed)('totalPricedQuantity', 'productMinQuantity', function () {
      return this.totalPricedQuantity >= (this.productMinQuantity === undefined ? 0 : this.productMinQuantity);
    }),
    maxQuantityIsValid: (0, _object.computed)('totalPricedQuantity', 'productMaxQuantity', function () {
      const maxQuantity = this.productMaxQuantity === undefined ? 0 : this.productMaxQuantity;
      const totalPricedQuantity = this.totalPricedQuantity;
      if (maxQuantity === 0) {
        return true;
      }
      return totalPricedQuantity <= maxQuantity;
    }),
    availableProductQuantity: (0, _object.computed)('defaultProductMaxQuantity', 'persistedProductQuantity', 'productMaxQuantity', function () {
      const productMaxQuantity = this.productMaxQuantity;
      if (productMaxQuantity === 0) {
        return this.defaultProductMaxQuantity;
      }
      return productMaxQuantity - this.persistedProductQuantity;
    }),
    canIncrementForProduct: (0, _object.computed)('productMaxQuantity', 'totalPricedQuantity', 'availableProductQuantity', function () {
      const availableProductQuantity = this.availableProductQuantity;
      const maxQuantity = this.productMaxQuantity;
      const totalPricedQuantity = this.totalPricedQuantity;
      if ((0, _utils.isEmpty)(maxQuantity) || maxQuantity === 0) {
        return true;
      }
      return totalPricedQuantity < availableProductQuantity;
    }),
    totalPrice: (0, _object.computed)('basketItems.@each.{price,quantity}', function () {
      const basketItems = this.basketItems;
      return basketItems.reduce((total, basketItem) => total + basketItem.price * basketItem.quantity, 0).toFixed(2);
    }),
    dateLabel: (0, _object.computed)('date', function () {
      const date = this.date;
      if ((0, _utils.isEmpty)(date)) {
        return 'Choose date';
      }
      return _moment.default.utc(date).format('DD/MM/YYYY');
    }),
    selectVisitorsLabel: (0, _object.computed)('isBasketItemsEmpty', 'totalVisitors', 'basketItems.[]', 'emptySelectVisitorsLabel', 'ticketHolderLabel', function () {
      const totalVisitors = this.totalVisitors;
      const isBasketItemsEmpty = this.isBasketItemsEmpty;
      const emptyLabel = this.emptySelectVisitorsLabel;
      const ticketHolderLabel = this.ticketHolderLabel;
      if (isBasketItemsEmpty) {
        return emptyLabel;
      }
      if (totalVisitors === 1) {
        return totalVisitors + ' ' + ticketHolderLabel;
      }
      return totalVisitors + ' ' + ticketHolderLabel + 's';
    }),
    isSelectVisitorsDisabled: (0, _object.computed)('requiresDate', 'date', function () {
      const requiresDate = this.requiresDate;
      const date = this.date;
      if (requiresDate) {
        return (0, _utils.isEmpty)(date);
      }
      return false;
    }),
    skuFields: (0, _object.computed)('skuFieldArrays.[]', function () {
      let skuFieldArrays = this.skuFieldArrays;
      return skuFieldArrays.reduce((skuFields, skuFieldArray) => skuFields.pushObjects(skuFieldArray.toArray()), []);
    }),
    skuValueDates: (0, _object.computed)('skuDateValueArrays.[]', function () {
      let skuDateValueArrays = this.skuDateValueArrays;
      return skuDateValueArrays.reduce((dates, skuDateValueArray) => dates.pushObjects(skuDateValueArray), []);
    }),
    days: (0, _object.computed)('displayDate', 'dates.[]', 'product', 'showCapacityKey', 'skus.[]', function () {
      let startDay = this.displayDate.clone().startOf('month').subtract(1, 'day').weekday(1);
      let finishDay = this.displayDate.clone().endOf('month').subtract(1, 'day').weekday(7);
      let days = [];
      while (startDay.isBefore(finishDay)) {
        let key = [];
        let isSelectable = false;
        if (startDay.day() == 6) {
          key.pushObject('is-saturday');
        }
        let skus = this.skus.filter(sku => {
          let bookableDate = sku.get('attrs').bookableDate;
          let name = sku.get('attrs').name;
          return (name == 'Adult' || name == 'Person') && (0, _moment.default)(bookableDate).isSame(startDay);
        });
        if ((0, _utils.isEmpty)(skus) == false) {
          let priceBand = skus[0].attrs?.priceBand ?? null;
          if (!(0, _utils.isNone)(priceBand)) {
            key.pushObject(priceBand.toLowerCase());
          }
        }
        let stockQuantity = skus.reduce((total, sku) => total + sku.get('stockQuantity'), 0);
        let sessionCount = skus.get('length');
        if (stockQuantity > 0) {
          isSelectable = true;
        }
        if (stockQuantity == 0 && skus.get('length') > 0) {
          key.pushObject('sold-out');
        }
        if (this.showCapacityKey && stockQuantity > 0) {
          let lowThreshold = this.product.get('attributes')['low_capacity_threshold'] * sessionCount;
          let mediumThreshold = this.product.get('attributes')['medium_capacity_threshold'] * sessionCount;
          if (stockQuantity < lowThreshold) {
            key.pushObject('busy');
          } else if (stockQuantity < mediumThreshold) {
            key.pushObject('moderate');
          } else {
            key.pushObject('quiet');
          }
        }
        days.pushObject({
          day: startDay.clone(),
          name: startDay.format('YYYY-MM-DD'),
          isSelectable: isSelectable,
          isDisabled: !isSelectable,
          dateKey: key.join(' ')
        });
        startDay.add(1, 'day');
      }
      return days;
    }),
    sortedSkus: (0, _object.computed)('skus.[]', 'date', function () {
      let skus = this.skus;
      let skuField = (0, _object.get)(skus, 'firstObject.skuFields').findBy('slug', 'session-start-time');
      if ((0, _utils.isPresent)(skuField)) {
        return skus.toArray().sort((sku1, sku2) => {
          let sku1Field = sku1.skuFields.findBy('slug', 'session-start-time');
          let sku2Field = sku2.skuFields.findBy('slug', 'session-start-time');
          if ((0, _object.get)(sku1Field, 'values.firstObject') > (0, _object.get)(sku2Field, 'values.firstObject')) {
            return 1;
          } else if ((0, _object.get)(sku1Field, 'values.firstObject') < (0, _object.get)(sku2Field, 'values.firstObject')) {
            return -1;
          }
          return 0;
        });
      } else {
        return skus;
      }
    }),
    filteredSkus: (0, _object.computed)('sortedSkus.[]', 'date', 'requiresDate', function () {
      const skus = this.sortedSkus;
      const requiresDate = this.requiresDate;
      let date = null;
      if (requiresDate === false) {
        return skus;
      }
      date = this.date.format();
      return skus.filter(sku => sku.skuFields.filterBy('slug', 'bookable-date').findBy('values.firstObject', date));
    }),
    skuGroups: (0, _object.computed)('filteredSkus.[]', 'showCapacityKey', 'product', function () {
      return this.filteredSkus.reduce((skuGroups, sku) => {
        let sessionStartTime = sku.get('attributes.session-start-time.firstObject');
        let key = 'none';
        if ((0, _utils.isPresent)(sessionStartTime)) {
          key = sessionStartTime.substring(0, sessionStartTime.length - 3);
        }
        let skuGroup = skuGroups.find(skuGroup => skuGroup.key === key);
        if ((0, _utils.isNone)(skuGroup)) {
          let cssClasses = [];
          let stockQuantity = sku.get('stockQuantity');
          if (this.showCapacityKey) {
            let lowThreshold = this.product.get('attributes')['low_capacity_threshold'];
            let mediumThreshold = this.product.get('attributes')['medium_capacity_threshold'];
            if (stockQuantity == 0) {
              cssClasses.pushObject('sold-out');
            } else if (stockQuantity < lowThreshold) {
              cssClasses.pushObject('busy');
            } else if (stockQuantity < mediumThreshold) {
              cssClasses.pushObject('moderate');
            } else {
              cssClasses.pushObject('quiet');
            }
          }
          skuGroups.pushObject({
            key: key,
            skus: [sku],
            stockQuantity: sku.get('stockQuantity'),
            cssClasses: cssClasses.join(' ')
          });
        } else {
          skuGroup.skus.pushObject(sku);
        }
        return skuGroups;
      }, []);
    }),
    didReceiveAttrs() {
      this._super();
      (0, _object.set)(this, 'showCustomAttributesForm', false);
      (0, _object.set)(this, 'showCrossSell', false);
      this.loadSkusTask.perform(this.requiresDate, this.displayDate);
    },
    init() {
      this._super(...arguments);
      if ((0, _utils.isPresent)(this.minDate) && this.minDate.isAfter((0, _moment.default)())) {
        (0, _object.set)(this, 'displayDate', this.minDate);
      }
    },
    addToBasketTask: (0, _emberConcurrency.task)(function* () {
      if (this.hasGiftInfo && this.showCustomAttributesForm === false) {
        (0, _object.set)(this, 'showCustomAttributesForm', true);
        return;
      }
      try {
        yield this.goods.addToBasket(this.basketItems, (0, _object.get)(this, 'session.basket'));

        // if (this.hasCrossSell === true) {
        //   set(this, 'showCrossSell', true);
        //   return;
        // }

        let productAttrs = this.product.get('attrs');
        let visitors = this.basketItems.map(basketItem => {
          let skuAttributes = basketItem.get('sku').get('attributes');
          let name = this.tickets.skuName(skuAttributes, productAttrs.skuTitleTemplate);
          return {
            name,
            quantity: basketItem.get('quantity')
          };
        });
        let selectionStr = JSON.stringify(visitors);
        let date = (0, _moment.default)(this.date).format('DDMMYY');
        let upsellSlugs = productAttrs.upsellProductSlugs;
        return this.router.transitionTo('upsell', {
          queryParams: {
            s: selectionStr,
            d: date,
            u: upsellSlugs
          }
        });
      } catch (e) {
        (0, _object.set)(this, 'errors', e.errors);
      }
    }).restartable(),
    loadSkusTask: (0, _emberConcurrency.task)(function* (requiresDate, displayDate) {
      let skus = [];
      if (requiresDate === false) {
        skus = yield this.store.query('sku', {
          filter: {
            product_id: this.product.id
          }
        });
      } else {
        (0, _object.set)(this, 'displayDate', displayDate);
        let startDate = displayDate.clone().startOf('month');
        let finishDate = displayDate.clone().endOf('month');
        if (startDate.isSameOrBefore((0, _moment.default)(), 'day')) {
          startDate = (0, _moment.default)().startOf('day');
        }
        skus = yield this.store.query('sku', {
          filter: {
            product_id: this.product.id,
            query: [['bookable-date', 'is_same_or_after', startDate.format('YYYY-MM-DDTHH:mm:ss\\Z')], ['bookable-date', 'is_same_or_before', finishDate.format('YYYY-MM-DDTHH:mm:ss\\Z')]]
          }
        });
      }
      (0, _object.set)(this, 'skus', skus);
    }).restartable(),
    actions: {
      onAddToBasket() {
        this.addToBasketTask.perform();
      },
      changeDisplayDate(displayDate) {
        this.loadSkusTask.perform(this.requiresDate, displayDate);
      },
      selectDate(date) {
        let basketItems = this.basketItems;
        if (!date.isSame(this.date)) {
          basketItems.invoke('destroyRecord');
          basketItems.clear();
        }
        (0, _object.setProperties)(this, {
          date: date,
          isShowingCalendar: false
        });
      },
      selectVisitors() {
        (0, _object.set)(this, 'isShowingVisitors', false);
      }
    }
  });
  _exports.default = _default;
});