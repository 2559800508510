define("cheddargorge/services/opening-times", ["exports", "@ember/service", "@ember/object", "moment"], function (_exports, _service, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const seasonEndDate = (0, _moment.default)('2023-12-31');
  var _default = _service.default.extend({
    today() {
      return (0, _moment.default)().utc().startOf('day');
    },
    todaysKey: (0, _object.computed)('dateKeys', 'today', function () {
      let today = this.today().format();
      return this.dateKeys.find(dateKey => dateKey.dates.find(date => date === today));
    }),
    todaysOpeningTime: (0, _object.computed)('todaysKey', function () {
      let todaysKey = this.todaysKey;
      if (todaysKey === false) {
        return 'N/A';
      }
      return todaysKey.openTime;
    }),
    todaysClosingTime: (0, _object.computed)('todaysKey', function () {
      const key = this.todaysKey;
      if (key === false) {
        return 'N/A';
      }
      return key.closeTime;
    }),
    isOpenToday: (0, _object.computed)(function () {
      return this.isOpenOn(this.today());
    }),
    isClosedToday: (0, _object.computed)(function () {
      return this.isClosedOn(this.today());
    }),
    isOpenOn( /*date*/
    ) {
      let todaysKey = this.todaysKey;
      if (typeof todaysKey == 'object') {
        return true;
      }
      return false;
    },
    /*
     * If no date is set in Goods, then default to closed
     */
    isClosedOn(date) {
      return !this.isOpenOn(date);
    },
    getDateKey(getDateKeyDate) {
      return this.dateKeys.find(dateKey => dateKey.dates.find(date => date === getDateKeyDate));
    },
    range(startDate, finishDate) {
      let day = startDate.utc().startOf('day').clone(); // Make sure start date matches Goods format
      let isBetweenFinishDate = finishDate.clone();
      let datesInRange = [];
      while (day.isBefore(isBetweenFinishDate)) {
        let dayDateKey = this.dateKeys.find(dateKey => dateKey.dates.find(date => date === day.format()));
        if (dayDateKey) {
          datesInRange.push({
            day: day.clone(),
            dateKey: dayDateKey.slug
          });
        } else {
          datesInRange.push({
            day: day.clone(),
            dateKey: 'closed'
          });
        }
        day = day.add(1, 'days');
      }
      return datesInRange;
    },
    seasonEndDate: (0, _object.computed)(() => {
      return seasonEndDate;
    })
  });
  _exports.default = _default;
});