define("cheddargorge/controllers/products/partners", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _controller.default.extend({
    goods: (0, _service.inject)("goods"),
    products: (0, _computed.alias)("model.products"),
    partnerSku: (0, _computed.alias)("model.partnerSku"),
    partnerType: (0, _object.computed)("partnerSkuFields", function () {
      const partnerSkuFields = this.partnerSkuFields;
      return partnerSkuFields["partner-type"][0];
    }),
    partnerSkuFields: (0, _object.computed)("partnerSku", function () {
      const goods = this.goods;
      const partnerSku = this.partnerSku;
      return goods.fieldsToHash(partnerSku.skuFields);
    })
  });
  _exports.default = _default;
});