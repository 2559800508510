define("cheddargorge/models/event", ["exports", "ember-data", "ember-goods/models/content-entry"], function (_exports, _emberData, _contentEntry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;
  var _default = _contentEntry.default.extend({
    name: attr(),
    slug: attr(),
    heroBannerImage: attr(),
    cardImage: attr(),
    cardCaption: attr(),
    ctaLink: attr(),
    ctaLabel: attr(),
    isExternalLink: attr('boolean'),
    boolean: attr(),
    location: attr(),
    times: attr(),
    price: attr(),
    dates: attr(),
    description: attr(),
    needToKnow: attr(),
    gridImage1: attr(),
    gridImage2: attr(),
    gridImage3: attr(),
    gridImage4: attr(),
    gridImage5: attr(),
    gridImage6: attr(),
    metaTitle: attr(),
    order: attr(),
    metaDescription: attr(),
    purchaseCtaLabel: attr(),
    purchaseCtaLink: attr()
  });
  _exports.default = _default;
});